<template>
  <section class="vld-parent container">
    <b-card title="My Account">
      <b-tabs class="pt-3" content-class="mt-3">
        <b-tab title="Profile" active>
          <b-row>
            <b-col>
              <UserInformation
                :user="userToEdit"
                :isLoading="isLoading"
                :userMigrated="userMigrated"
                v-on:saveUser="saveUser($event, false)"
                v-on:resetUser="loadUser()"
              ></UserInformation>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<UserReferenceNumberRestrictions />
						</b-col>
					</b-row>
        </b-tab>
        <b-tab title="Change Password">
          <b-row>
            <b-col>
              <ChangePasswordCard />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ChangePasswordCard from "@/components/user/ChangePasswordCard";
import UserInformation from "@/components/users/UserInformation";
import UserReferenceNumberRestrictions from "@/components/user/UserReferenceNumberRestrictions";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const ProfileRepository = RepositoryFactory.get("profileRepository");
import { handleRequestError } from "@/shared/utils/response-error-handler";

export default {
  components: {
    Loading,
    ChangePasswordCard,
    UserInformation,
		UserReferenceNumberRestrictions
  },
  mounted() {
    this.loadUser(); 
  },
  data() {
    return {
      isLoading: false,
      userToEdit: null,
      
      isLoading: false
    };
  },
  methods: {
    async loadUser() {
      this.isLoading = true;

      try {
        let response = await ProfileRepository.getById(
          this.loggedInUser.userId
        );
        
        this.userToEdit = response.data;
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }

      this.isLoading = false;
    },
    async saveUser(user) {
      try {
        this.isLoading = true;

        let result = await ProfileRepository.update(user.id, user);
 
        this.isLoading = false;
        this.wasUpdated = true;

        let message = "User Information updated successfully.";

        this.$snotify.success(message, "Success", {
          timeout: 1500,
          showProgressBar: true
        });

        this.userToEdit = result.data;
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters("user", ["loggedInUser"]),
    userMigrated() {
      return this.$route.query.userMigrated;
    }
  }
};
</script>

<style scoped>
</style>
