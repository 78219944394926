<template id="tmplUserRefNumRestrictions">
	<div class="mb-3">
		<loading
			:is-full-page="false"
			color="#114C8E"
			:active.sync="isLoading"
		></loading>
		<b-card title="Reference Number Restrictions">
			<!-- Select row -->
			<b-row class="justify-content-center">
				<b-col class="text-center">
					Column #1
					<treeselect
						instanceId="userRefNumbTreeSelectCol1"
						v-model="columnOneSelect"
						:multiple="false"
						:options="refNumTypesCol1"
						valueFormat="object"
					>
					</treeselect>
				</b-col>
				<b-col class="text-center">
					Column #2
					<treeselect
						instanceId="userRefNumbTreeSelectCol2"
						v-model="columnTwoSelect"
						:multiple="false"
						:options="refNumTypesCol2"
						valueFormat="object"
					>
					</treeselect>
				</b-col>
				<b-col class="text-center">
					Column #3
					<treeselect
						instanceId="userRefNumbTreeSelectCol3"
						v-model="columnThreeSelect"
						:multiple="false"
						:options="refNumTypesCol3"
						valueFormat="object"
					>
					</treeselect>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col cols="12">
          <b-button
            variant="primary"
            class=" btn-block-xs-only"
            @click="saveReferenceNumberRestrictions"
            >Save Reference Number Restrictions</b-button
          >

          <b-button
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="resetReferenceNumberRestrictions"
            >Reset Reference Number Restrictions</b-button
          >
        </b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Treeselect } from "@riophae/vue-treeselect";
import { mapState, mapGetters, mapActions } from 'vuex';
import { RepositoryFactory } from "@/services/RepositoryFactory";
const CustomerRepository = RepositoryFactory.get("customersRepository");

export default {
	components: {
		Treeselect,
    Loading
  },
	data () {
		return {
			isLoading: false,
			columnOneSelect: null,
			columnTwoSelect: null,
			columnThreeSelect: null
		}
	},
	computed: {
    ...mapState('user', {
      refNumTypesAvailable: state => state.refNumTypesAvailable,
			refNumTypesSelected: state => state.refNumTypesSelected
    }),
		...mapGetters("user", ["loggedInUser"]),
		refNumTypesCol1(){
			return this.parsedRefNumTypesForSelect(1);
		},
		refNumTypesCol2(){
			return this.parsedRefNumTypesForSelect(2);
		},
		refNumTypesCol3(){
			return this.parsedRefNumTypesForSelect(3);
		}
  },
	async mounted(){
		this.isLoading = true;
		await this.fetchUserAssignedRefNumTypes();
		await this.fetchUserAvailableRefNumTypes();
		this.fillRefNumTypesSelectedData();
		this.isLoading = false;
	},	
	methods: {
		...mapActions("user", ["fetchUserAssignedRefNumTypes", "fetchUserAvailableRefNumTypes"]),
		async resetReferenceNumberRestrictions(){
			this.isLoading = true;
			await this.fetchUserAssignedRefNumTypes();
			this.fillRefNumTypesSelectedData();
			this.isLoading = false;
		},
		parsedRefNumTypesForSelect(columnNumber) {
			// Check if refNumTypesAvailable is available
			if (this.refNumTypesAvailable && this.refNumTypesAvailable.length > 0) {
				return this.refNumTypesAvailable.map(refNumType => ({
					id: `${refNumType.abbr}-${refNumType.division}`,
					label: `${refNumType.name} - ${refNumType.division}`,
					abbr: refNumType.abbr,
					colNumber: columnNumber
				}));
			} else {
				// Handle the case where refNumTypesAvailable is not available yet
				return [];
			}
		},
		async saveReferenceNumberRestrictions(){
			this.isLoading = true;
			const selectedValues = [this.columnOneSelect, this.columnTwoSelect, this.columnThreeSelect].filter(value => value);
				
			const requestData = {
				customerId: this.loggedInUser.customerId,
				userId: this.userId,
				userReferenceNumberTypes: selectedValues.map(value => ( { abbr: value.abbr, colNumber: value.colNumber } ))
			};
				
			const response = await CustomerRepository.assignCustUserRefNumbPermissions(requestData);
	
			if(response.success){
				this.$snotify.success("Reference number types assigned successfully.", "Success", {
					timeout: 1500,
					showProgressBar: true
				})
				await this.fetchUserAssignedRefNumTypes();	
			}else{
				this.$snotify.error(response.data, "Error")
			}
			this.isLoading = false;
		},
		getDivisionForRefNumTypeSelected(abbr){
			const refNumTypeFound = this.refNumTypesAvailable.find(x => x.abbr === abbr);
			if(refNumTypeFound) return refNumTypeFound.division;
			return '';
		},
		fillRefNumTypesSelectedData(){
			this.refNumTypesSelected.forEach(element => {
				let division = this.getDivisionForRefNumTypeSelected(element.abbr);
				switch(element.colNumber){
					case 1:
						this.columnOneSelect = {
							id: `${element.abbr}-${division}`, // -${element.division}`,
							label: `${element.name} - ${division}`, // - ${element.division}`,
							abbr: element.abbr,
							colNumber: element.colNumber
						};
						break;
					case 2:
						this.columnTwoSelect = {
							id: `${element.abbr}-${division}`, // -${element.division}`,
							label: `${element.name} - ${division}`, // - ${element.division}`,
							abbr: element.abbr,
							colNumber: element.colNumber
						};
						break;
					case 3:
						this.columnThreeSelect = {
							id: `${element.abbr}-${division}`, // -${element.division}`,
							label: `${element.name} - ${division}`, // - ${element.division}`,
							abbr: element.abbr,
							colNumber: element.colNumber
						};
						break;
					default: 
				}
			});
		}
	}
}
</script>

<style lang="scss" scoped>

</style>