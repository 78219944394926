<template>
  <div class="mb-3">
    <b-card title="User Information">
      <b-alert :show="showMigratedMessage" variant="warning" dismissible>
        Please fill out the following information to complete your migration.
      </b-alert>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <b-row v-if="user">
        <b-col lg="6" md="6" sm="12">
          <b-form-group label="First Name" label-for="txtCustomerName">
            <b-form-input
              id="txtFirstName"
              name="txtFirstName"
              v-model="user.firstName"
              :class="validateState('firstName') == false ? 'is-invalid' : ''"
              aria-describedby="txtFirstName-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="txtFirstName-live-feedback"
              >First name is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <b-form-group label="Last Name" label-for="txtCustomerName">
            <b-form-input
              id="txtLastName"
              v-model="user.lastName"
              :class="validateState('lastName') == false ? 'is-invalid' : ''"
              aria-describedby="txtLastName-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="txtLastName-live-feedback"
              >Last name is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col lg="6" md="6" sm="12">
          <b-form-group label="Email" label-for="txtEmail">
            <b-form-input
              id="txtEmail"
              v-model.trim="user.emailAddress"
              :class="
                validateState('emailAddress') == false ? 'is-invalid' : ''
              "
              aria-describedby="txtEmail-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="txtEmail-live-feedback"
              >Valid email is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <b-form-group label="Phone Number" label-for="txtPhoneNumber">
            <masked-input
              id="txtPhoneNumber"
              class="form-control"
              placeholder="(555) 555-5555"
              v-model="$v.user.phoneNumber.$model"
              :class="validateState('phoneNumber') == false ? 'is-invalid' : ''"
              :guide="true"
              :mask="[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]"
              aria-describedby="txtPhone-live-feedback"
            ></masked-input>
            <b-form-invalid-feedback id="txtPhone-live-feedback"
              >Phone number is required and should be (###)
              ###-####.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group>
            <b-form-checkbox v-model="user.customerAdmin"
              >Customer Admin?</b-form-checkbox
            >
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            variant="primary"
            class=" btn-block-xs-only"
            @click="saveUser"
            >Save User Information</b-button
          >

          <b-button
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="$emit('resetUser')"
            >Reset User Information</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>  

<script>

import { mapActions, mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";
const checkValidPhoneNumber = inputValidations.checkValidPhoneNumber;

import { handleRequestError } from "@/shared/utils/response-error-handler";
import MaskedInput from "vue-text-mask";

export default {
  mixins: [validationMixin],
  props: ["userMigrated", "user", "isLoading"],
  components: {
    MaskedInput,
    Loading
  },
  data() {
    return {
      inputValidations: inputValidations,
      wasUpdated: false
    };
  },
  async mounted() {},
  methods: {
    ...mapActions("users", ["setSelectedUser"]),
    async saveUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("saveUser", this.user);
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    }
  },
  computed: {
    showMigratedMessage() {
      return this.userMigrated == 1 && this.wasUpdated == false;
    }
  },
  validations: {
    user: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      emailAddress: {
        required,
        email
      },
      phoneNumber: {
        required,
        checkValidPhoneNumber
      }
    }
  }
};
</script>
