<template>
  <b-card title="Change Password">
    <p class="pt-2">
      Password must be at least 8 characters long, contain uppercase, lowercase,
      numeric, and special characters.
    </p>
    <b-form-group label="Old Password" label-for="txtOldPassword">
      <b-form-input
        id="txtOldPassword"
        type="password"
        class="form-control"
        v-model="oldPassword"
        :state="
          inputValidations.chkState('oldPassword', $v) == false ? false : null
        "
      />
      <b-form-invalid-feedback
        v-if="!$v.oldPassword.required && $v.oldPassword.$dirty"
        >Old Password is required.</b-form-invalid-feedback
      >
      <b-form-invalid-feedback
        v-if="!$v.oldPassword.minLength && $v.oldPassword.$dirty"
        >Old Password must be at least 8 characters
        long.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group label="New Password" label-for="txtPassword">
      <b-form-input
        id="txtPassword"
        type="password"
        class="form-control"
        v-model="newPassword"
        :state="
          inputValidations.chkState('newPassword', $v) == false ? false : null
        "
      />
      <b-form-invalid-feedback
        v-if="!$v.newPassword.required && $v.newPassword.$dirty"
        >Password is required.</b-form-invalid-feedback
      >
      <b-form-invalid-feedback
        v-if="!$v.newPassword.minLength && $v.newPassword.$dirty"
        >Password must be at least 8 characters long.</b-form-invalid-feedback
      >
      <b-form-invalid-feedback
        v-if="!$v.newPassword.checkStrongPassword && $v.newPassword.$dirty"
        >Password does not meet minimum requirements.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Confirm Password" label-for="txtConfirmPassword">
      <b-form-input
        id="txtConfirmPassword"
        type="password"
        class="form-control"
        v-model="confirmNewPassword"
        :state="
          inputValidations.chkState('confirmNewPassword', $v) == false
            ? false
            : null
        "
        @keyup.enter="changePassword()"
      />
      <b-form-invalid-feedback
        :state="inputValidations.chkState('confirmNewPassword', $v)"
        >Matching password required.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-row class="text-center">
      <b-col>
        <b-button variant="primary" class="px-4" @click="changePassword()"
          >Change Password</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import "vue-loading-overlay/dist/vue-loading.css";
import inputValidations from "@/shared/utils/input-validation";
import { handleRequestError } from "../../shared/utils/response-error-handler";

const checkStrongPassword = inputValidations.checkStrongPassword;

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      inputValidations: inputValidations,
      showValidation: false,
      validation: ""
    };
  },
  methods: {
    async changePassword() {
      this.$root.$emit("isLoading", true);
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          var data = {
            passwordInfo: {
              CurrentPassword: this.oldPassword,
              NewPassword: this.newPassword,
              ConfirmNewPassword: this.confirmNewPassword
            },
            jwtToken: this.$store.state.user.jwtToken
          };

          await this.$store.dispatch("user/changePasword", data);

          this.$v.$reset();
          this.oldPassword = "";
          this.newPassword = "";
          this.confirmNewPassword = "";

          this.$snotify.success("Password changed successfully", "Success");
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
      this.$root.$emit("isLoading", false);
    }
  },
  validations: {
    oldPassword: {
      required,
      minLength: minLength(8)
    },
    newPassword: {
      required,
      minLength: minLength(8),
      checkpassword: checkStrongPassword
    },
    confirmNewPassword: {
      required,
      sameAsPassword: sameAs("newPassword")
    }
  }
};
</script>

<style scoped>
</style>
